import React from "react"
import { Helmet } from "react-helmet"

import { Link } from "gatsby"

import "./zharco.css"
import Footer from "src/component/Footer"
import generalcss from "./general.module.css"
// import whitecss from "./layout.module.css"
import blackcss from "./black.module.css"

// const cx = classnames.bind(css)

const AlbumHeader = ({ album, gallery }) => (
  <div className={generalcss.header}>
    <span className={generalcss.title}>{album.title}</span>
    <Link to={gallery.url} className={generalcss.path}>
      {gallery.title}
    </Link>
    <SiteName />
  </div>
)

const GalleryHeader = ({ gallery }) => (
  <div className={generalcss.header}>
    {gallery ? <span className={generalcss.title}>{gallery.title}</span> : null}
    <SiteName />
  </div>
)

const SiteHeader = () => (
  <div className={generalcss.header}>
    <SiteName />
  </div>
)

const SiteName = () => (
  <Link className={generalcss.sitename} to="/">
    <span className={generalcss.sitenameBegin}>zhar</span>
    <span className={generalcss.sitenameEnd}>co</span>
  </Link>
)

const DEFAUL_SEO = {
  title: "zharco",
  lang: "en",
  description: "Site of Zhanya Zharikova",
  keywords: ["zhaco", "portfolio", "zhanya zharikova"],
}

const SEO = (params: {
  title?: string
  lang?: string
  description?: string
  keywords?: [string]
}) => {
  const { lang, description } = { ...DEFAUL_SEO, ...params }
  const title = params.title
    ? `${params.title} | ${DEFAUL_SEO.title}`
    : DEFAUL_SEO.title
  const keywords = Array.isArray(params.keywords)
    ? DEFAUL_SEO.keywords.concat(params.keywords)
    : DEFAUL_SEO.keywords
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      /* titleTemplate={`%s | ${DEFAUL_SEO.title}`} */
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://omnigon.com.ua/static/74739961a09f5f9ffa0eeb8acebdfc73/863b0/hands.jpg`,
        },
      ].concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : []
      )}
    />
  )
}

type LayoutParams = {
  album?: TitledUrl
  gallery?: TitledUrl
  children: any
}

const Layout = (params: LayoutParams) => {
  const { album, gallery, children } = params
  const css = album || gallery ? blackcss : { page: undefined }
  const layoutParams = { album, gallery }
  const header = album ? (
    <AlbumHeader {...layoutParams} />
  ) : gallery ? (
    <GalleryHeader {...layoutParams} />
  ) : (
    <SiteHeader />
  )
  const title = album ? album.title : gallery ? gallery.title : undefined
  return (
    <div className={css.page}>
      <SEO title={title} />
      {header}
      {children}
      <Footer />
    </div>
  )
}

export default Layout
