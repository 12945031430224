import React from "react"
import { Link } from "gatsby"
import css from "./footer.module.css"

const Footer = () => {
  return (
    <div className={css.pageFooter}>
      <Link to={"/contacts"}>
        &copy; zhenya zharikova &nbsp;&nbsp; +380-67-3851218 &nbsp;
        +380-66-7678088 &nbsp; Skype: jein88 &nbsp; zhenya.zharikova at
        gmail.com
      </Link>
    </div>
  )
}

export default Footer

{
  /* <script type="text/javascript">

  _ga_id = 'UA-16452796-2';
  var _gaq = _gaq || [];
  _gaq.push(['_setAccount', _ga_id]);
  _gaq.push(['_setDomainName', '.zharco.com']);
  _gaq.push(['_trackPageview']);

  (function() {
    var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
    ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
  })();

  
	function recordOutboundLink(link, category, action) {
	  try {
		var pageTracker = _gat._getTracker(_ga_id);
		pageTracker._trackEvent(category, action);
		setTimeout('document.location = "' + link.href + '"', 100)
	  }catch(err){}
	}
	
</script> */
}
